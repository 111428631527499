<template>
  <div class="boardpage" :class="['boardpage', className]">
    <ul>
      <li @click="goToPage(navMin)">‹<!--img src="@/assets/img/arrowL.png" /--></li>
      <!--li class="ft20" @click="goToPage(pageNum - 1)">‹</li-->
      <template v-for="i in navArray" v-bind:key="`nav${i}`">
        <li :class="{ on: pageNum === i }"
           @click="goToPage(i)">{{i}}</li>
      </template>
      <!--li class="ft20" @click="goToPage(pageNum + 1)">›</li-->
      <li @click="goToPage(navMax)">›<!--img src="@/assets/img/arrowR.png" /--></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageNum: {
      type: Number,
      default: 1,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10,
      required: true
    },
    totalCount: {
      type: Number,
      default: 0,
      required: true
    },
    navSize: {
      type: Number,
      default: 10
    },
    className: {
      type: String,
      required: false
    }
  },
  computed: {
    navArray () {
      return new Array(Math.max(this.navMin, this.navEnd - this.navStart + 1))
        .fill(this.navStart).map((n, i) => n + i)
    },
    navStart () {
      return Math.max(this.pageNum - (this.navSize - 1 - Math.min(Math.floor(this.navSize / 2),
        this.navMax - this.pageNum)), this.navMin)
    },
    navEnd () {
      return Math.min(this.pageNum + Math.max(Math.floor(this.navSize / 2),
        this.navSize - this.pageNum), this.navMax)
    },
    navMin () {
      return 1
    },
    navMax () {
      return Math.floor((this.totalCount - 1) / this.pageSize) + 1
    },
    totalPage () {
      return Math.ceil(this.totalCount / this.pageSize)
    }
  },
  methods: {
    goToPage (no) {
      if (this.navArray.length > 1) {
        this.$emit('goToPage', no)
      }
    }
  }
}
</script>
<style scoped>
.boardpage{text-align: center;}
.boardpage ul{display: flex;justify-content: center;align-items: center;gap: 5px;}
.boardpage ul li {cursor: pointer;font-size: 20px;background: none; color: #BCBFCE;width: 25px;height: 25px;display: flex;align-items: center;justify-content: center;}
.boardpage ul li.on { color: #1A65F4; }
.boardpage ul li img{vertical-align: middle;}
#PTbodyWrap .boardpage ul {margin-top: 20px;}
#PTbodyWrap .boardpage ul li {background: #555; border: 0; }
#PTbodyWrap .boardpage ul li.on {background: #555;}
.partnerPaging ul li { background: #555; border: 0; }
.partnerPaging ul li.on {background: #555;}
</style>
<style scoped src="@/styles/common.css"></style>
